import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/comm.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css'
import VueScrollTo from 'vue-scrollto'
import '@/permission'
// 图片预览组件
import ImagePreview from "@/components/ImagePreview/index.vue"
import ImageP from "@/components/ImagePreview/index__1.vue"
// 图片上传组件
import ImageUpload from "@/components/ImageUpload/index.vue"
//import './aoto-update.js'
Vue.component('ImageUpload', ImageUpload)
Vue.component('ImagePreview', ImagePreview)
Vue.component('ImageP', ImageP)
Vue.use(ElementUI)
Vue.use(VueScrollTo)
// 默认选项
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
// 解决bug：页面/路由跳转后，滚动条消失，页面无法滚动
router.afterEach((to, from, next) => {
  document.querySelector("body").setAttribute("style", "overflow: auto !important;")
});

// 创建事件总线
export const eventLogin = new Vue();
export const eventLoginOut = new Vue();
// export const eventIsLogin = new Vue();
