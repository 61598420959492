import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';//引入持久化插件

Vue.use(Vuex)

export default new Vuex.Store({
  //plugins配置持久化
  plugins: [createPersistedState({
    paths: ["isRealName"]
  })],
  // data
  state: {
    BannerHeight: 0,
    headerShadowActive: false,
    headerShow: false,
    headerLogoShow: true,
    navDarkActive: false,
    articlePath: '',
    isRealName: false,

  },
  mutations: {
    setRealNameStatus(state, status) {
      state.isRealName = status;
    },
    setBannerHeight(state, value) {
      state.BannerHeight = value.bannerHeight
    },
    setShadowActive(state, value) {
      state.headerShadowActive = value.headerShadowActive
    },
    setHeaderShow(state, value) {
      //state.headerShow = value.headerShow
    },
    setHeaderLogo(state, value) {
      state.headerLogoShow = value.headerLogoShow
    },
    setNavDarkActive(state, value) {
      state.navDarkActive = value.navDarkActive
    },
    SET_ARTICLE_PATH: (state, value) => {
      state.article_path = value.path
    },


  },
  actions: {
    setArticlePath({ commit }, path) {
      return new Promise(() =>
        commit('SET_ARTICLE_PATH'), path)
    },


  },
  modules: {}
})
