import service from "./service";

export function get(url, params) {
    const config = {
        method: "GET",
        url,
    }
    if (params) { config.params = params }
    return service(config)
}

export function post(url, params) {
    const config = {
        method: "POST",
        url,
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    }

    if (params) { config.data = JSON.stringify(params) }
    return service(config)
}