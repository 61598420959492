<template>
    <div id="app">
        <div v-if="isbrowserName">
            <keep-alive>
                <router-view v-if="$route.meta.keepAlive" />
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive" />
            <el-backtop :bottom="80" :right="60" :visibility-height="280">
                <svg t="1697872932886" aria-hidden="true" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="3962" width="200" height="200">
                    <path
                        d="M528 67.5l-16-16.7-15.9 16.7c-7.3 7.7-179.9 190.6-179.9 420.8 0 112 40 210.1 73.5 272.7l6.2 11.6H627l5.9-13c3.1-6.8 75-167.8 75-271.3 0-230.2-172.6-413.1-179.9-420.8z m-16 48.8c19 22.9 51.9 66.1 82.3 122.5H429.8c30.3-56.4 63.3-99.6 82.2-122.5z m86.3 612.2H422.5c-25.7-50.6-62.2-140.1-62.2-240.2 0-75 20.8-145.5 47.7-205.4h208.2c26.8 59.9 47.6 130.3 47.6 205.4-0.1 78.3-48.7 200.4-65.5 240.2z"
                        fill="#1E59E4" p-id="3963"></path>
                    <path
                        d="M834.7 623.9H643.3l6.7-27.3c9.1-37 13.7-73.4 13.7-108.2 0-44.8-7.7-92-22.9-140.3l-17-54 49.1 28.3c99.8 57.6 161.8 164.7 161.8 279.5v22z m-135.9-44.2h90.9c-5.7-71-38.8-137.2-91.3-184.6 6.3 31.7 9.4 62.9 9.4 93.2 0.1 29.7-3 60.3-9 91.4zM380.1 623.9H189.3v-22.1c0-114.8 62-221.9 161.8-279.5l49.1-28.3-17 54c-15.2 48.3-22.9 95.5-22.9 140.3 0 34.5 4.5 71 13.4 108.4l6.4 27.2z m-145.8-44.2H325c-5.9-31.3-8.8-61.9-8.8-91.4 0-30.3 3.2-61.5 9.4-93.2-52.5 47.5-85.6 113.6-91.3 184.6zM512 529.5c-45 0-81.6-36.6-81.6-81.6s36.6-81.6 81.6-81.6 81.6 36.6 81.6 81.6-36.6 81.6-81.6 81.6z m0-119c-20.7 0-37.5 16.8-37.5 37.5s16.8 37.5 37.5 37.5 37.5-16.8 37.5-37.5-16.8-37.5-37.5-37.5z"
                        fill="#1E59E4" p-id="3964"></path>
                    <path
                        d="M512 999.7l-20.3-20.3c-28.8-28.6-68.3-67.9-68.3-111.6 0-48.9 39.8-88.6 88.6-88.6 48.9 0 88.6 39.8 88.6 88.6 0 43.6-24.4 67.9-64.8 108.2L512 999.7z m0-176.4c-24.5 0-44.5 20-44.5 44.5 0 21.5 23.8 48.4 44.5 69.5 33.6-33.7 44.4-47 44.4-69.5 0.1-24.6-19.9-44.5-44.4-44.5z"
                        fill="#FF5A06" p-id="3965"></path>
                </svg>
            </el-backtop>
            <kefu />
        </div>
        <div v-if="!isbrowserName">
            <div v-html="html"></div>
        </div>
    </div>
</template>

<script>
import bowser from 'bowser';
import kefu from './components/kefu.vue';
export default {
    name: 'index',
    components: { kefu },
    data() {
        return {
            browserName: null,
            isbrowserName: true,
            html: `<!DOCTYPE html>
<html lang="zh-CN">

<head>
    <meta charset="UTF-8" />
    <title>请升级您的浏览器</title>
    <meta http-equiv="X-UA-Compatible" content="IE=Edge,chrome=1">
    <meta name="renderer" content="webkit">
    <base target="_blank" />
    <style type="text/css">
        html,
        body,
        div,
        span,
        applet,
        object,
        iframe,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        pre,
        a,
        abbr,
        acronym,
        address,
        big,
        cite,
        code,
        del,
        dfn,
        em,
        img,
        ins,
        kbd,
        q,
        s,
        samp,
        small,
        strike,
        strong,
        sub,
        sup,
        tt,
        var,
        b,
        u,
        i,
        center,
        dl,
        dt,
        dd,
        ol,
        ul,
        li,
        fieldset,
        form,
        label,
        legend,
        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td,
        article,
        aside,
        canvas,
        details,
        embed,
        figure,
        figcaption,
        footer,
        header,
        hgroup,
        menu,
        nav,
        output,
        ruby,
        section,
        summary,
        time,
        mark,
        audio,
        video {
            border: 0;
            font-size: 100%;
            font: inherit;
            vertical-align: baseline;
            margin: 0;
            padding: 0
        }

        article,
        aside,
        details,
        figcaption,
        figure,
        footer,
        header,
        hgroup,
        menu,
        nav,
        section {
            display: block
        }

        body {
            line-height: 1
        }

        ol,
        ul {
            list-style: none
        }

        blockquote,
        q {
            quotes: none
        }

        blockquote:before,
        blockquote:after,
        q:before,
        q:after {
            content: none
        }

        table {
            border-collapse: collapse;
            border-spacing: 0
        }

        a {
            text-decoration: none;
            color: #0072c6;
        }

        a:hover {
            text-decoration: none;
            color: #004d8c;
        }

        body {
            width: 960px;
            margin: 0 auto;
            padding: 10px;
            font-size: 14px;
            line-height: 24px;
            color: #454545;
            font-family: 'Microsoft YaHei UI', 'Microsoft YaHei', DengXian, SimSun, 'Segoe UI', Tahoma, Helvetica, sans-serif;
            overflow-y: scroll
        }

        h1 {
            font-size: 40px;
            line-height: 80px;
            font-weight: 100;
            margin-bottom: 10px;
        }

        h2 {
            font-size: 20px;
            line-height: 25px;
            font-weight: 100;
            margin: 10px 0;
        }

        em {
            color: red
        }

        p {
            margin-bottom: 10px;
        }

        hr {
            margin: 20px 0;
            border: 0;
            border-top: 1px solid #dadada
        }

        span {
            display: block;
            font-size: 12px;
            line-height: 12px;
        }

        .clean {
            clear: both;
        }

        .browser {
            padding: 10px 10px;
        }

        .browser li {
            width: auto;
            padding: 0 80px;
            margin-top: 30px;
            height: 34px;
            line-height: 22px;
            float: left;
            list-style: none;
            background: url('./assets/ie.png') no-repeat;
            padding-left: 40px
        }

        .browser .browser-firefox {
            background-position: 0 -34px
            
        }

        .browser .browser-edge {
            background-position: 0 -68px;
            margin-left: 0px
        }
        browser .browser-opera {
            background-position: 0 -68px;
            margin-left: 0px
        }


        .browser .browser-safari {
            background-position: 0 -170px;
            margin-left: -27px
            
        }
    </style>
</head>

<body style="margin-top:50px">
    <h1>请升级您的浏览器，以便我们更好的为您提供服务！</h1>
    <p>您正在使用 Internet Explorer 的早期版本（IE11以下版本或使用该内核的浏览器）,或老旧的早期浏览器版本。这意味着在升级浏览器前，您将无法访问此网站。</p>
    <hr>
    <h2>请注意：建议不要使用2345浏览器，360浏览器，QQ浏览器，搜狗浏览器等，此类浏览器采用了IE的早期版本内核</h2>
    <h2>由于：微软公司对Windows XP、Windows 7 及 Internet Explorer 早期版本的支持已经结束</h2>
    <p>自 2016 年 1 月 12 日起，Microsoft 不再为 IE 11
        以下版本提供相应支持和更新。没有关键的浏览器安全更新，您的电脑可能易受有害病毒、间谍软件和其他恶意软件的攻击，它们可以窃取或损害您的业务数据和信息。请参阅 <a
            href="https://www.microsoft.com/zh-cn/WindowsForBusiness/End-of-IE-support">微软对 Internet Explorer 早期版本的支持将于
            2016 年 1 月 12 日结束的说明</a> 。</p>
    <hr>
    <h2>您可以选择更先进的浏览器</h2>
    <p>推荐使用以下浏览器的最新版本。如果您的电脑已有以下浏览器的最新版本则直接使用该浏览器访问即可。</p>
    <ul class="browser">
        <li class="browser-chrome">
            <a href="https://www.google.cn/chrome/browser/desktop/index.html?hl=zh-CN&standalone=1">
                谷歌浏览器  <span style="color:red;display: inherit;">（首选）</span>
                <span>GoogleChrome</span>
                
            </a>
        </li>
        <li class="browser-firefox">
            <a href="https://www.mozilla.org/zh-CN/firefox/new/"> 火狐浏览器
                <span>Mozilla Firefox</span>
            </a>
        </li>
        <li class="browser-edge">
            <a href="https://www.microsoft.com/zh-cn/edge/download?form=MH17CR">
                微软Edge浏览器  <span style="color:red;display: inherit;">（首选）</span>
                <span>Microsoft Edge</span>
            </a>
        </li>
        <li class=".browser-opera">
            <a href="http://www.oupeng.com/download">
                欧朋浏览器
                <span>Opera </span>
            </a>
        </li>
        <li class=".browser-safari">
            <a href="https://www.apple.com.cn/safari/index.html">
                苹果浏览器
                <span>Safari </span>
                <span>尊贵的MacOs用户，您可以直接使用safari进行访问</span>
            </a>
        </li>
        <div class="clean"></div>
    </ul>
    <hr>
    
</body>

</html>`
        }
    },
    methods: {
    },

    created() {
        const browser = bowser.getParser(window.navigator.userAgent);

        // 定义支持的浏览器及其最低版本要求
        const supportedBrowsers = {
            'Chrome': 0,
            'Firefox': 70, // 如果没有特定版本要求，可以将其设置为0或忽略
            'Microsoft Edge': 100,
            'Opera': 57,
            'Safari': 12,
            'WeChat': 0
        };
        const browserName = browser.getBrowserName();
        //alert(browser.getBrowserName(),parseInt(browser.getBrowserVersion(), 10))
        // console.log(browser.getBrowserName(), parseInt(browser.getBrowserVersion(), 10));
        const version = parseInt(browser.getBrowserVersion(), 10);
        if (browserName == 'WeChat') {
            this.$notify({
                title: `警告，您正在使用受限的内核浏览`,
                message: `为了给予您更好的服务和更好的体验，请您更换您的浏览器，如不更换可能某些功能将受限，建议使用最新版本谷歌浏览器！`,
                type: 'warning',
                duration: 0,
                position: 'top-left',
                offset: 290
            });
        }

        //检查浏览器是否受支持
        if (!(browserName in supportedBrowsers) || supportedBrowsers[browserName] > version) {
           // this.isbrowserName = false
        }
    }

}
</script>

<style lang = "less" scoped>
.icon {
    width: 1.3em;
    height: 1.3em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}

.el-backtop {
    width: 48px;
    height: 48px;
    box-shadow: 0 0 6px rgba(0, 0, 0, .2);
}
</style>
