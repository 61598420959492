import router from './router'
import { getToken } from '@/utils/auth' // 从cookie获取 token
import { Message } from 'element-ui';

const whiteList = ['/login', '/signup', '/', '/index', '/news', '/newsDetail', '/forgotPassword', '/changePassword','/website']
router.afterEach((to, from, next) => {
    window.scrollTo(-1000, -1000)
})

router.beforeEach((to, from, next) => {
    // 确定用户是否已登录
    const hasToken = getToken()

    if (hasToken) {
        if (to.path === '/login' || to.path === '/signup') {
            next({ path: `/user-center?pid=${router.history.current.query.pid}` })
        } else {
            
            next()
        }
    } else {
        if (to.path === '/login' || to.path === '/signup') {
            next()
        } else {
            if (whiteList.indexOf(to.path) !== -1) {
                next()
            } else {
                Message('您需要先登录后在进入该页面哦！');
                // console.log(router.history.current.query.pid);
                next(`/login?redirect=${to.path}&pid=${router.history.current.query.pid}`)
            }
        }
    }
    if (to.path === '/') {
        document.title = '烈火骑士-www.6188cq.com-首页';
      }
})