import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    redirect: '/',
  },
  {//首页
    path: '/',
    name: 'index',
    component: () => import('../views/index/index.vue'),
    props: (route) => ({ pid: 1 }),
    meta: {
      keepAlive: true // 标记为缓存组件
    },
  },
  {//登录
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    props: (route) => ({ pid: route.query.pid })
  },
  {//注册
    path: '/signup',
    name: 'signup',
    component: () => import('../views/signUp.vue'),
    props: (route) => ({ pid: route.query.pid })
  },
  {//新闻
    path: '/news',
    name: 'news',
    component: () => import('../views/news/news.vue'),
    props: (route) => ({ pid: route.query.pid })
  },
  {//会员中心
    path: '/user-center',
    name: 'userCenter',
    component: () => import('../views/userCenter/user-center.vue'),
    props: (route) => ({ pid: route.query.pid })
  },
  {//详情
    path: '/newsDetail',
    meta: {
      referrer: '来源页面'
    },
    name: 'newsDetail',
    component: () => import('../views/news/newsDetail.vue'),
    props: (route) => ({ pid: route.query.pid })
  },
  {//活动详情
    path: '/activitiesDetail',
    meta: {
      referrer: '来源页面'
    },
    name: 'activitiesDetail',
    component: () => import('../views/news/activitiesDetail.vue'),
    props: (route) => ({ pid: route.query.pid })
  },
  {//更改密码
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('../views/changePassword.vue'),
    props: (route) => ({ pid: route.query.pid })
  },
  {//找回密码
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: () => import('../views/forgotPassword.vue'),
    props: (route) => ({ pid: route.query.pid })
  },
  {//藏宝阁
    path: '/cbg',
    name: 'cbg',
    component: () => import('../views/treasure-house/index.vue'),
    props: (route) => ({ pid: route.query.pid })
  },
  {//支付中转
    path: '/pay-status',
    name: 'payStatus',
    component: () => import('../views/userCenter/payStatus/index.vue'),
    props: (route) => ({ pid: route.query.pid })
  },
  {//进入官网
    path: '/website',
    name: 'website',
    component: () => import('../views/website/website.vue'),
    props: (route) => ({ pid: route.query.pid })
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
